import React from "react";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useReduceMotion } from "frontend/hooks";
import { useGlobal } from "frontend/hooks";
import SiteHeader from "../SiteHeader";
import Footer from "../Footer";
import Detail from "../Detail";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Filters from "../Filters";
import Map from "../Map";
import Results from "../Results";
import styles from "./Container.module.scss";
import { useTranslation } from "react-i18next";

function Container() {
  const reduceMotion = useReduceMotion();
  const { googleMaps } = useGlobal();
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div className={styles.layout}>
      <SiteHeader containerClass={styles.layout__header} />
      <main className={styles.layout__main} id="MainContent">
        <Route path="/locations/:location">
          {({ match }) => (
            <CSSTransition
              in={match !== null}
              timeout={reduceMotion ? 20 : 300}
              classNames="slide"
              unmountOnExit
            >
              <Detail containerClass={styles.layout__sidebar} />
            </CSSTransition>
          )}
        </Route>
        <Sidebar containerClass={styles.layout__sidebar}>
          <Header />
          <Filters />
          <Results />
          <Footer />
        </Sidebar>
        <Map containerClass={styles.layout__map} />
      </main>
    </div>
  );
}

Container.propTypes = {};

export default Container;
