import React from "react";
import { useQueryFilters } from "frontend/hooks";
import PropTypes from "prop-types";

const Form = ({ children, className, id, role }) => {
  const { updateQuery } = useQueryFilters();

  function handleSubmit(event) {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    const formDataObj = Object.fromEntries(formData);

    const date = formData.getAll("date");
    const time = formData.getAll("time");
    const category = formData.getAll("category");
    const attribute = formData.getAll("attribute");
    const q = formDataObj.q;

    updateQuery({
      ...formDataObj,
      date,
      time,
      category,
      attribute,
      ...(q ? { lat: null, lng: null } : {}),
    });
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={className}
      id={id}
      {...(role ? { role } : {})}
    >
      {children}
      <button type="submit" className="sr-only">
        Submit
      </button>
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  role: PropTypes.role,
};

export default Form;
