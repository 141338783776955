import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AttributeIcon from "../AttributeIcon";

import styles from "./AttributeFilter.module.scss";

const AttributeFilter = ({
  labelKey,
  value,
  icon,
  initChecked = false,
  ...checkboxProps
}) => {
  const { t } = useTranslation();

  const inputRef = useRef();

  // These fields should reset to false, not the inital value.
  useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.checked = initChecked;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <label className={styles.checkbox}>
      <input
        ref={inputRef}
        type="checkbox"
        className={styles.checkbox__input}
        value={value}
        {...checkboxProps}
      />
      <span className={styles.checkbox__check}>
        <AttributeIcon icon={icon} large />
      </span>
      <span className={styles.checkbox__label}>
        {t(labelKey, { ns: "attributes" })}
      </span>
    </label>
  );
};

AttributeFilter.propTypes = {
  labelKey: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool,
  icon: PropTypes.string,
  initChecked: PropTypes.bool,
};

export default AttributeFilter;
