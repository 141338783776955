import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { getDirectionsLink } from "frontend/helpers";
import { locationShape } from "frontend/shapes";
import { LOCATION_ZOOM } from "frontend/shared/constants";
import { useLocations, useMobile } from "frontend/hooks";
import { getFormattedHours } from "frontend/helpers";
import { Button, Link } from "../ButtonOrLink";
import AttributeList from "../AttributeList";
import { IconArrow } from "../Icons";
import Distance from "../Distance";

import styles from "./Location.module.scss";
import CategoriesList from "../CategoriesList";

const Location = ({ location }) => {
  const { setMarkerId, setPosition } = useLocations();
  // const map = useMap();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { setView } = useMobile();
  const {
    id,
    distance,
    latitude,
    longitude,
    name,
    address,
    location_attributes: attributes,
    location_categories: categories,
    location_hours: locationHours,
    hours_comment: hoursComment,
    phone,
    served_areas: servedAreas,
    website,
  } = location;

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      setMarkerId(id);
      setPosition({
        center: { lat: latitude, lng: longitude },
        zoom: LOCATION_ZOOM,
      });
      setView("map");
    },
    [id, setMarkerId, setPosition, setView, latitude, longitude]
  );

  return (
    <li className={styles.location} data-id={location.id}>
      <Link
        className={styles.location__title}
        onClick={handleClick}
        aria-label={t("detail.view_on_map", { name })}
      >
        <h4>{name}</h4>
      </Link>
      <div className={styles.location__description}>
        {!!categories && categories.length > 0 && (
          <div className={styles.location__top}>
            <div>
              <CategoriesList categories={categories} showDescriptions />
            </div>
          </div>
        )}
        <div className={styles.location__left}>
          {distance && <Distance value={distance} />}
          {address && (
            <div className={styles.location__address}>
              <h5>{t("detail.address_header")}</h5>
              <p>{address}</p>
            </div>
          )}
          <div className="is-hidden-print">
            <Button
              href={getDirectionsLink({ latitude, longitude, address })}
              target="_blank"
            >
              {t("detail.directions_button")}
            </Button>
          </div>
          <div className={styles.location__print}>
            {phone && (
              <div>
                <h5>{t("detail.phone_header")}</h5>
                <p>{phone}</p>
              </div>
            )}
            {website && (
              <div>
                <h5>{t("detail.website_header")}</h5>
                <p>{website}</p>
              </div>
            )}
          </div>
          <div>
            {servedAreas && servedAreas.length > 0 && (
              <div>
                <h5>{t("detail.served_areas_header")}</h5>
                <ul>{servedAreas && <li>{servedAreas.join(", ")}</li>}</ul>
              </div>
            )}
          </div>
        </div>
        <div className={styles.location__right}>
          {((locationHours && locationHours.length > 0) || hoursComment) && (
            <h5>{t("detail.hours_header")}</h5>
          )}
          {locationHours && locationHours.length > 0 && (
            <div>
              <ul>
                {getFormattedHours(locationHours, language).map((item, i) => (
                  <li
                    key={i}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item),
                    }}
                  ></li>
                ))}
              </ul>
            </div>
          )}
          {hoursComment && <p>{hoursComment}</p>}
        </div>
        <div
          className={classNames("is-hidden-print", styles.location__moreinfo)}
        >
          <Link
            to={{
              pathname: `/locations/${id}`,
              search: window.location.search,
            }}
            icon={<IconArrow small rotation={270} />}
          >
            {t("results.more_information_button")}
          </Link>
        </div>
        <div className={classNames(styles.location__attributes)}>
          {attributes && attributes.length > 0 && (
            <AttributeList alignRight attributes={attributes} />
          )}
        </div>
      </div>
    </li>
  );
};

Location.propTypes = {
  location: PropTypes.shape(locationShape).isRequired,
};

export default Location;
