import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGlobal, useQueryFilters } from "frontend/hooks";

import styles from "./FiltersCampaign.module.scss";
import { Radio } from "../../form";

const FiltersCampaign = ({ initValue }) => {
  const { t } = useTranslation();
  const { activeCampaign } = useGlobal();
  const { updateQuery } = useQueryFilters();
  const defaultRef = useRef(null);
  const campaignRef = useRef(null);

  function handleChange(event) {
    updateQuery({
      category: null,
      attribute: null,
      campaign: event.target.value,
    });
  }

  // This field should reset to default campaign.
  useEffect(() => {
    if (!defaultRef.current || !campaignRef.current) return null;
    defaultRef.current.checked = !initValue || initValue === "0";
    campaignRef.current.checked = initValue === "1";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!activeCampaign?.id) return null;

  return (
    <fieldset className={styles.filtersCampaign}>
      <legend className={styles.filtersCampaign__label}>
        {t("filters.campaign_select_label")}
      </legend>
      <div role="radiogroup" className={styles.filtersCampaign__options}>
        <Radio
          ref={defaultRef}
          name="campaign"
          value="0"
          onChange={handleChange}
          defaultChecked={true}
        >
          {t("filters.default_campaign_select_label")}
        </Radio>
        <Radio
          ref={campaignRef}
          name="campaign"
          value={activeCampaign.id}
          defaultChecked={false}
          onChange={handleChange}
        >
          {t("campaign_headline", { ns: "active_campaign" })}
        </Radio>
      </div>
      <p className={styles.filtersCampaign__description}>
        {t("campaign_paragraph", { ns: "active_campaign" })}
      </p>
    </fieldset>
  );
};

FiltersCampaign.propTypes = {
  initValue: PropTypes.string,
};

export default FiltersCampaign;
